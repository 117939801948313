export const MusicHallData = [
    {
        id:32,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/r1fgmIanNkk/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/r1fgmIanNkk"
    },
    {
        id:1,
        text:"Director",
        imgLink:"https://i.ibb.co/Cwxgp8n/YUVALMEVULBAL1920.jpg",
        youtubeLink:"https://youtu.be/LDOmVnCNmeA"
    },
    {
        id:2,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/qM8F_cCVmDg/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/qM8F_cCVmDg"
    },
    {
        id:3,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/d2fK-hL4H8s/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/d2fK-hL4H8s"
    },
    {
        id:4,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/kupP7HVIxoY/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/kupP7HVIxoY"
    },
    {
        id:5,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/FTFaKOh4TxM/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/FTFaKOh4TxM"
    },
    {
        id:6,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/0fQPiliWb8o/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/0fQPiliWb8o"
    },
    {
        id:7,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/l1ilihthYXE/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/l1ilihthYXE"
    },
    {
        id:8,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/fhVjk4j5xNA/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/fhVjk4j5xNA"
    },
    {
        id:9,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/tUZSymdOSOs/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/tUZSymdOSOs"
    },
    {
        id:10,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/oMLwjEJjArs/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/oMLwjEJjArs"
    },
    {
        id:11,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/HCd5xanVBfM/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/HCd5xanVBfM"
    },
    {
        id:12,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/JwTtlNGXaDQ/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/JwTtlNGXaDQ"
    },
    {
        id:13,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/5iPhaXFMzMU/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/5iPhaXFMzMU"
    },
    {
        id:14,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/1FQeyJGeuE4/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/1FQeyJGeuE4"
    },
    {
        id:15,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/fB1Zpbfqc0c/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/fB1Zpbfqc0c"
    },
    {
        id:16,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/KddrNOHyPCA/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/KddrNOHyPCA"
    },

    {
        id:17,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/VOyCOkCeGzA/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/VOyCOkCeGzA"
    },
    {
        id:18,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/I3KdKebjEas/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/KddrNOHyPCA"
    },
    {
        id:19,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/84i31vc89wM/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/84i31vc89wM"
    },
    {
        id:20,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/FP3C3JjE4Oc/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/FP3C3JjE4Oc"
    },
    {
        id:21,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/xpGOFUbjKTk/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/xpGOFUbjKTk"
    },
    {
        id:22,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/N_eQL8rJYTQ/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/UlVMI4uhjaE"
    },
    {
        id:23,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/6yrJvX5FDc4/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/6yrJvX5FDc4"
    },
    {
        id:24,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/BmMaZrx7-W8/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/BmMaZrx7-W8"
    },
    {
        id:25,
        text:"Director",
        imgLink:"https://i3.ytimg.com/vi/QnJZ53QW5r8/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/QnJZ53QW5r8"
    },
    {
        id:26,
        text:"Co - Director",
        imgLink:"https://i3.ytimg.com/vi/nW7LfxcJryg/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/nW7LfxcJryg"
    },
    {
        id:27,
        text:"Producer",
        imgLink:"https://i3.ytimg.com/vi/F0aj-8Goxg0/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/F0aj-8Goxg0"
    },
    {
        id:28,
        text:"Strategy Consulting",
        imgLink:"https://i3.ytimg.com/vi/V2Af-EUld44/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/V2Af-EUld44"
    },
    {
        id:29,
        text:"Strategy Consulting",
        imgLink:"https://i3.ytimg.com/vi/q1dQF3Ya5MQ/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/q1dQF3Ya5MQ"
    },
    {
        id:30,
        text:"Actor",
        imgLink:"https://i3.ytimg.com/vi/Hc6uJx73sh4/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/Hc6uJx73sh4"
    },
    {
        id:31,
        text:"Actor",
        imgLink:"https://i3.ytimg.com/vi/SI3pf9rxETk/maxresdefault.jpg",
        youtubeLink:"https://youtu.be/SI3pf9rxETk"
    },
] 





























































































